$theme: core;
@use "design-system" as ds;

.label {
  align-self: center;
  border: none;
  cursor: pointer;
  display: flex;
}

.marking {
  @include ds.typography(underline, $theme, $responsive: false);
  @include ds.text-color(primary, $theme: $theme);
  padding: 9px 20px 5px;
  background-color: transparent;
}

.wide {
  @include ds.text-color(primary, $theme: $theme);
  width: calc(100% - 1px);
  padding: 0 4px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
}

.fullscreen {
  position: sticky;
  top: 0;
}

.afterLinks {
  // Special handling...
  // ...for integration ads on the bottom of articles
  right: 1px;
  left: auto;
}

.icon {
  margin-left: 5px;
}

.link {
  @include ds.text-color(brand, $theme: $theme);
  font-weight: bold;
}

.close {
  text-align: right;
  cursor: pointer;
}

.closeSpan {
  cursor: pointer;
}

.footer {
  @include ds.text-color(secondary, $theme: $theme);
  margin-top: 30px;
  text-align: center;
  font-size: small;
}
.footerLink {
  @include ds.text-color(secondary, $theme: $theme);
  font-weight: bold;
}

.footerCreative {
  margin-top: 20px;
}
