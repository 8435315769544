$theme: core;
@use "design-system" as ds;

.feed {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  gap: ds.spacing(base);
  // overflow: hidden;

  @media (min-width: ds.$screen-size--small) {
    max-width: ds.$content-width--max;
  }
}

.feedContainer {
  display: flex;
  margin: 0 auto;
  position: relative;
  margin-top: ds.spacing();
  justify-content: center;

  &.withWidescreenAd {
    @media (min-width: ds.$screen-size--small) {
      gap: ds.spacing();
    }
  }

  &:not(.firstFeed) {
    @media (min-width: ds.$screen-size--small) {
      margin-top: ds.spacing();
    }
    margin-top: ds.spacing(base);
  }
}

.listHeader {
  margin-bottom: ds.spacing(base);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ds.spacing();
  padding: 0 ds.spacing();

  @media (min-width: ds.$screen-size--small) {
    margin-bottom: ds.spacing(none);
    padding: 0;
  }
}

.listHeaderSmall {
  @media (min-width: ds.$screen-size--small) {
    margin-bottom: ds.spacing(base);
  }
}

.listTitle {
  flex-grow: 1;
  @include ds.typography(h2, $theme: $theme);
  margin: ds.spacing(base) 0;
}
