$theme: core;
@use "design-system" as ds;

.rectangleSkeleton {
  border-radius: 2px;
  display: inline-block;
  line-height: 100%;
  width: 100%;
  background-color: #fff;
  background-size: 1000px 1000px;
  @include ds.background-for-shimmer-effect(
    placeholder--primary,
    primary,
    $theme: $theme
  );
  animation: shimmerEffect 1.5s linear infinite forwards;

  &.desktopOnly {
    display: none;
    @media (min-width: ds.$screen-size--medium) {
      display: inline-block;
    }
  }
}

/* Skeleton animation*/
@keyframes shimmerEffect {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}
