$theme: core;
@use "design-system" as ds;

.groupTitle {
  padding: ds.spacing() ds.spacing(regular-increased);
  @include ds.typography(h3, $theme);
  align-items: center;
  display: flex;
  flex-direction: row;

  &.hidden {
    @include ds.a11y-hidden();
  }
}
