$theme: core;
@use "design-system" as ds;

.topicArticleContainer {
  border: 1px solid black;
  @include ds.border(
    divider--subtle,
    $t: 0,
    $b: 0,
    $l: 1px,
    $r: 1px,
    $theme: $theme
  );

  &.firstArticle {
    @include ds.border(
      divider--subtle,
      $t: 1px,
      $l: 1px,
      $r: 1px,
      $theme: $theme
    );

    border-radius: 4px 4px 0 0;
  }
}

.separator {
  @include ds.border(divider--subtle, $t: 0, $b: 1px, $theme: $theme);
  margin: 0 ds.spacing();
}

.endOfFeed {
  border-radius: 0 0 ds.spacing("base") ds.spacing("base");
  border-bottom: 1px solid ds.color(ui, divider--subtle, $theme: $theme);
}
