$theme: core;
@use "design-system" as ds;

.webviewContainer {
  margin-top: ds.spacing();
  @include ds.pad-lr();
  @include ds.stack();

  &.mainResource {
    margin: 0;
    padding: 0;
  }
}

.longform,
.explaining {
  @media (min-width: ds.$screen-size--small) {
    @include ds.pad-lr(medium);
  }
}

.webviewIframe {
  border: none;
  overflow: hidden;
}
