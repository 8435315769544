$theme: core;
@use "design-system" as ds;

.inputFieldContainer {
  @include ds.border(divider--subtlest, $t: 1px, $theme: $theme);

  display: flex;
  flex-direction: row;
  gap: ds.spacing(base);

  &.showBackground {
    @include ds.background(primary, $theme: $theme);
    padding: ds.spacing() ds.spacing(regular-increased);
  }
}

.inputField {
  padding: 10px ds.spacing(regular-increased);
  @include ds.border(divider--subtle, $a: 1px, $theme: $theme);
  @include ds.element-background(form-field, $theme: $theme);
  @include ds.typography(form-text, $theme);
  @include ds.text-color(primary, $theme: $theme);
  border-radius: 100vi;
  flex-grow: 1;
  min-width: 0;

  &:focus {
    outline: none;
  }
}

.groupContainer {
  padding: ds.spacing() ds.spacing();

  @media (min-width: ds.$screen-size--small) {
    padding: ds.spacing() 0;
  }
}

.submitFieldContainer  {
  flex-grow: 0;
}
