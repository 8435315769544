$theme: core;
@use "design-system" as ds;

.adContainer {
  display: none;

  @media (min-width: ds.$screen-size--large) {
    display: flex;
    justify-content: center;
    margin-top: ds.spacing(base);

    @include ds.border(divider--subtle, $t: 1px, $b: 1px, $theme: $theme);
    @include ds.box-shadow(inset, $theme: $theme);
    @include ds.background(card--subtle, $theme: $theme);
  }
}

.adContent {
  margin: ds.spacing(base) 0;
  width: ds.$site-width--max;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
