$theme: core;
@use "design-system" as ds;

.container {
  display: flex;
  margin: ds.spacing("base") auto 0 auto;
  gap: ds.spacing();
  justify-content: center;

  &.modal {
    height: 100%;
  }
}

.articleSkeletonContainer {
  @include ds.card($theme: $theme, $collapsing: true);
  width: 100vw;
  @media (min-width: ds.$screen-size--small) {
    width: ds.$content-width--max;
  }
  display: flex;
  flex-direction: column;
  gap: ds.spacing();
  margin-top: ds.spacing("base");

  &.modal {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }
}

.articleTextSkeletons {
  padding: ds.spacing();
  display: flex;
  flex-direction: column;
  gap: ds.spacing();
}

.imageSkeleton {
  aspect-ratio: auto 640 / 320;
}

.modalImageSkeleton {
  margin-top: 48px;
}

.modal {
  margin-top: 0;
}

.sideAd {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

.hidden {
  visibility: hidden;
}
